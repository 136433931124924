@use "./includes" as *;

* {
  box-sizing: border-box;
}

// Scrollbar on chrome
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #222;
  border: 1px solid transparent;
  border-radius: 50px;

  &:hover {
    background: #333;
  }
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0 none #ffffff;
  border-radius: 53px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

html {
  overflow-y: scroll;
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  //overflow: hidden;
}

html, body, #root, #root .main-wrapper {
  height: 100%;
  width: 100%;
  background-color: $dark7;
  color: $dark0;
}

#root .main-content {
  flex-grow: 1;
  height: 100%;
  width: 100%;

  .page {
    padding: rem(20);
  }
}
