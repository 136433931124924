@use "./includes" as *;

.page-services {
  padding: rem(20);

  .no-results {
    width: 100%;
    margin-top: rem(20);
    margin-bottom: rem(20);
    text-align: center;
    font-size: 1.2em;
    padding: 1rem;
    border-top: 1px solid $dark4;
    border-bottom: 1px solid $dark4;
  }
}
