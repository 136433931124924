@use "./includes" as *;

.main-wrapper {
  display: flex;
  flex-direction: row;

  @media ($media-mobile) {
    flex-direction: column;
  }
}

.main-navbar {
  --main-size: #{rem(96)};
  --icon-size: #{rem(64)};
  --logo-pad: #{rem(10)};
  $main-size: var(--main-size);
  $icon-size: var(--icon-size);
  width: $main-size;

  @media ($media-mobile) {
    --main-size: #{rem(48)};
    --icon-size: #{rem(42)};
    width: 100%;
    height: $main-size;
  }

  .wrapper {
    display: flex;
    @media ($media-mobile) {
      display: block;
    }
  }

  .aside {
    flex: 0 0 $main-size;
    background-color: $dark5;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: rem(1) solid $dark5;
    gap: rem(5);

    @media ($media-mobile) {
      flex-direction: row;
      width: 100%;
    }
  }

  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    height: $main-size;
    margin-bottom: rem(20);
    padding: rem(15) rem(10) rem(10);

    @media ($media-mobile) {
      width: auto;
      height: $main-size;
      margin-bottom: 0;
      padding: 0;
      margin-right: 0;
    }

    img {
      width: auto;
      height: 100%;
      padding: rem(10);
    }
  }

  .main {
    flex: 1;
    background-color: $dark6;
  }

  .spacer {
    width: $icon-size;
    margin-top: auto;

    @media ($media-mobile) {
      width: auto;
      margin-top: 0;
      height: $icon-size;
      margin-left: auto;
    }
  }

  .main-link {
    width: $icon-size;
    height: $icon-size;
    border-radius: $md_radius;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dark0;

    &:last-child {
      margin-bottom: rem(10);
      @media ($media-mobile) {
        margin-bottom: 0;
        margin-right: rem(10);
      }
    }

    &:hover {
      background-color: $dark5;
    }
  }

  .main-link.active {
    &, &:hover {
      background-color: #1971c233;
      color: #a5d8ff;
    }
  }
}
