@use "./includes" as *;

.page-accounts {
  padding: rem(20);

  .account-select-item {
    background: $dark6;
    padding: rem(30);
    border: rem(1) solid $dark4;
    border-radius: rem(10);
    display: flex;
    flex-flow: row wrap;

    .account-actions {
      @media ($media-small-mobile) {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
