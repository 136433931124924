@use "./includes" as *;

.master-password {
  margin-top: rem(10);

  label {
    display: none;
    padding: rem(3);
    font-weight: bold;
    width: 100%;
  }

  .field {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;

    .text-input {
      flex-grow: 1;
      padding-right: rem(5);
    }
  }
}

.master-password-color-box {
  display: flex;
  flex-flow: row nowrap;
  margin: 0;

  .background {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: rem(5);

    .color-box {
      width: rem(30);
      height: rem(30);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: white;
      text-shadow: 0 rem(1) rem(1) black, 0 rem(-1) rem(1) black, rem(1) 0 rem(1) black, rem(-1) 0 rem(1) black;
    }
  }
}
