@use 'sass:math';

@function rem($px) {
  @return #{math.div($px, 16)}rem;
}

$dark0: #C1C2C5;
$dark1: #A6A7AB;
$dark2: #909296;
$dark3: #5C5F66;
$dark4: #373A40;
$dark5: #2C2E33;
$dark6: #25262B;
$dark7: #1A1B1E;
$dark8: #141517;
$dark9: #101113;

$gray0: #F8F9FA;
$gray1: #F1F3F5;
$gray2: #E9ECEF;
$gray3: #DEE2E6;
$gray4: #CED4DA;
$gray5: #ADB5BD;
$gray6: #868E96;
$gray7: #495057;
$gray8: #343A40;
$gray9: #212529;

$xs: rem(16 * 0.6);
$sm: rem(16 * 0.75);
$md: rem(16 * 0.9);
$lg: rem(16 * 1);
$xl: rem(16 * 1.2);

$md_radius: rem(8);

$media-desktop: 'min-width: 1024px';
$media-mobile: 'max-width: 1023px';
$media-small-mobile: 'max-width: 768px';
