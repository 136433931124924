@use "./includes" as *;

.page-secrets {
  padding: rem(20);

  .secret {
    position: relative;
    background-color: $dark6;
    border-radius: $md_radius;
    padding: rem(10);

    &:hover {
      background-color: $dark4;
    }

    &.invalid {
      background-color: mix(red, $dark6, 40%);

      &:hover {
        background-color: mix(red, $dark4, 40%);
      }
    }
  }

  .secret-name {
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: #11aaff;
    }
  }
}
