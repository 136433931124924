@use "./includes" as *;

.page-alphabets {
  padding: rem(20);

  .alphabet {
    position: relative;
    background-color: $dark6;
    border-radius: $md_radius;
    padding: rem(10);

    &:hover {
      background-color: $dark4;
    }
  }

  .alphabet-name {
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: #11aaff;
    }
  }
}
